.App {
  text-align: center;
}

header {
  height: 222px;
  width: 300px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

header .visual-01,
header .visual-02 {
  position: absolute;
  height: 100px;
}

header .visual-01 {
  top: 70px;
  left: 0;
  transform: scale(1.3) translate(20px, -20px);
}

header .visual-02 {
  bottom: 70px;
  right: 0;
  transform: scale(1.3) translate(-20px, 20px);
}

header h1 {
  font-weight: 400;
  font-size: 40px;
  position: relative;
  z-index: 1;
}

.precautions {
  color: #ac2200;
  font-size: 12px;
  background: #ffe7e1;
  padding: 16px 32px;
  margin-bottom: 40px;
}

.precautions.warning {
  color: #8a8100;
  background: #fffce1;
  margin-bottom: 16px;
}

h2 {
  font-size: 32px;
  margin-bottom: 16px;
}

.date-time {
  font-size: 18px;
  margin-bottom: 8px;
  margin-top: 8px;
}

.location {
  font-size: 14px;
  margin-bottom: 8px;
  margin-top: 8px;
}

.map-services a {
  font-size: 12px;
  display: inline-block;
  color: inherit;
  margin-left: 8px;
  margin-right: 8px;
  text-decoration: none;
}

hr {
  margin: 64px;
  border: 1px solid #ccc;
  border-bottom: none;
}

.notices {
  text-align: left;
  padding-left: 24px;
  word-break: keep-all;
}

.notices li {
  line-height: 1.4;
  margin: 8px 0;
}

.notices li a {
  text-underline-offset: 2px;
  color: inherit;
  font-weight: 500;
  color: #777;
}

.nametag-notice {
  font-size: 14px;
  text-align: left;
  margin-bottom: 32px;
}

img.sample_nametag {
  max-width: 100%;
}

.btn-link {
  display: inline;
  background: none;
  text-decoration: underline;
  text-underline-offset: 2px;
  border: none;
  padding: 0;
  font-weight: 500;
  color: #777;
  font-size: inherit;
  font-family: inherit;
}

.unattend {
  margin-top: 40px;
}